.error404Sec{padding: 50px 0; display: flex;flex-direction: column;align-items: center; justify-content: center; }
.mainHeading{font-size: 46px;font-weight: 600;color: #222;line-height: initial;}
.subHeading{position: absolute;color: #666666;font-size: 20px;left: 50%;top: 45px;text-transform: uppercase;transform: translate(-50%, 0);}
.error404Sec .imgBx{display: flex;gap: 30px;flex-direction: column;position: relative;}
.errorSubHeading{text-transform: uppercase;font-size: 20px;}


@media screen and (max-width:540px){
    .error404Sec .imgBx{max-width: 300px;}  
    .mainHeading{margin-bottom: 10px;font-size: 36px;} 
    .subHeading{top: 30px;font-size: 16px;} 
}